import { Location } from '@zupr/types/fo'
import { locationStaticMap, normalizeLatLng } from '@zupr/utils/geolocation'
import classnames from 'classnames'

import MapLoader from '../map/loader'
import Marker from '../map/marker'

import LocationPin from '../../../svg/location-pin.svg'

import '../../../scss/react/form/geolocation.scss'

interface StaticMapProps {
    locationId: Location['id']
    zoom?: number
    width?: number
    height?: number
    className?: string
}

export const StaticMap = ({
    zoom = 16,
    width = 558,
    height = 142,
    className,
    locationId,
}: StaticMapProps) => (
    <figure className={classnames('location-preview', className)}>
        <img
            src={locationStaticMap(locationId, {
                zoom,
                width,
                height: height + 30,
            })}
            alt="location on map"
        />
        <LocationPin className="location-preview-pin" />
    </figure>
)

export const StaticMapForLocation = ({ locationId, ...props }) => (
    <StaticMap locationId={locationId} {...props} />
)

interface Props {
    position: Location['geo_location'] | google.maps.LatLngLiteral
    zoom?: number
    height?: number
}

export const LocationMap = ({ position, zoom = 15, height }: Props) => {
    if (process.env.NODE_ENV === 'development')
        return <div style={{ height }} className="google-maps map"></div>

    if (!position) {
        console.warn('NO POSITION')
        return null
    }

    return (
        <MapLoader
            style={{ height }}
            center={normalizeLatLng(position)}
            zoom={zoom}
        >
            <Marker active position={position} />
        </MapLoader>
    )
}

export default StaticMapForLocation
