import { Variation } from '@zupr/types/fo'
import { List } from '@zupr/types/generic'
import { locationUrl } from '@zupr/utils/url'
import { useRouter } from 'next/router'

import Filters from '../../../products/filters'

import classNames from 'classnames'
import PaginationOffset from '../../../../../shared/components/pagination/offset'
import PaginationSort from '../../../../../shared/components/pagination/sort'

export const PaginationAndFilterButton = ({
    products,
    aggregations,
    location,
    categoryBreadcrumbs,
    filter,
    activeCategory,
    categories,
}) => {
    const { query } = useRouter()

    return (
        <div className="grid-list-pagination">
            <PaginationOffset {...query} count={products?.count} />
            <div className="grid-list-sorters">
                <Filters
                    aggregations={aggregations}
                    products={products}
                    baseUrl={locationUrl(location, 'products')}
                    categoryBreadcrumbs={categoryBreadcrumbs}
                    filter={filter}
                    productsAtLocation
                    categories={categories}
                    activeCategory={activeCategory}
                />
            </div>
        </div>
    )
}

interface PaginationAndSortProps {
    products: List<Variation>
    className?: string
}

const PaginationAndSort = ({ products, className }: PaginationAndSortProps) => {
    const { query } = useRouter()
    return (
        <div className={classNames('grid-list-pagination', className)}>
            <PaginationOffset {...query} count={products?.count} />
            <div className="grid-list-sorters">
                <PaginationSort
                    defaultOrdering="-product_locations.created"
                    choices={[
                        'product_locations.price',
                        '-product_locations.price',
                        '-product_locations.created',
                    ]}
                />
            </div>
        </div>
    )
}

export default PaginationAndSort
